.navAnimation {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.5s both;
  animation: slide-top 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.5s both;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }
}
