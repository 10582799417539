.appbar_per {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  transition: transform 0.3s ease-in-out;
}
.appbar_per_hide {
  transform: translateY(-100%);
}
.overflowFix {
  overflow-y: visible !important;
  overflow-x: hidden;
  padding: 0px !important;
}
