.seguir-container{
    width: 100%;
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}


.btn-seguircompra{
    background-color: black;
    color: #fff;
    font-size: 0.875rem;
    padding-top: 5px;
    padding-bottom: 5px;
    white-space: nowrap;
    text-transform: uppercase;
    width: 42%;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);

    font-family: poppins;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    text-decoration: none;

    display: block;
    
} 
@media (max-width: 520px) {
    .seguir-container{
        flex-direction: column;
    }
    .btn-seguircompra{
        width: 100%;
    }
    .btn-confirm{
        width: 100%;
        height: 50px;
    }
}

.btn-seguircompra:hover{
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    background-color: #d5d5d5;
}

.btn-confirm:hover{
    background-color: #2e80eb;
}